const getSlugByKey = (data, key, language) => {
  let slug = data?.allEditorialJson?.edges?.find(
    edge => edge?.node?.key === key && edge?.node?.language === language
  )?.node?.slug

  if (!slug) {
    slug = data?.allCategoryJson?.edges?.find(
      edge => edge?.node?.key === key && edge?.node?.language === language
    )?.node?.slug
  }
  return slug ? `/${slug}` : undefined
}

export default getSlugByKey
