import { graphql, useStaticQuery } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import getSlugByKey from "../utils/getSlugByKey"
import LanguageSelector from "./languageSelector"
import MenuItem from "./menuItem"

const Menu = ({ menuOpen }) => {
  const { t } = useTranslation()
  const { language } = useI18next()

  const data = useStaticQuery(graphql`
    query EditorialPathsQuery {
      allEditorialJson {
        edges {
          node {
            slug
            key
            language
          }
        }
      }
      allCategoryJson {
        edges {
          node {
            slug
            key
            language
          }
        }
      }
    }
  `)

  return (
    <>
      <div id="mySidenav" className={`sidenav ${menuOpen ? "open" : ""}`}>
        <div className="nav--container">
          <div className="nav--content">
            <nav>
              <ul>
                <MenuItem
                  href={getSlugByKey(data, "rebel-cosmetic", language)}
                  title={t("menu.rebelCosmetic")}
                />
                <MenuItem
                  href={getSlugByKey(data, "beauty", language)}
                  title={t("menu.beauty")}
                  // subItems={[
                  //   { itemTitle: t("menu.treatments"), itemHref: "#" },
                  //   { itemTitle: t("menu.products"), itemHref: "#" },
                  // ]}
                />
                <MenuItem
                  href={getSlugByKey(data, "sex", language)}
                  title={t("menu.sex")}
                />
                <MenuItem
                  href={getSlugByKey(data, "a-pact-with-ardaraz", language)}
                  title={t("menu.pact")}
                />
                <MenuItem href={"/contact"} title={t("menu.contact")} />
              </ul>
            </nav>
          </div>
          <div className="nav--footer">
            <LanguageSelector />
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu
