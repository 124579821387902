import { Link } from "gatsby-plugin-react-i18next"
import * as React from "react"

const MenuItem = ({ subItems = undefined, title, href = undefined }) => {
  const [pressed, setPressed] = React.useState(false)
  const switchPressed = () => setPressed(value => !value)

  return subItems ? (
    <li className={`nav--item ${pressed ? "open" : ""}`}>
      <span onClick={switchPressed} children={title} />
      <ul className={`nav--item--submenu`}>
        {subItems.map(({ itemTitle, itemHref }, index) => (
          <li key={`title${index}`}>
            <Link className="nav--item" to={itemHref} children={itemTitle} />
          </li>
        ))}
      </ul>
    </li>
  ) : (
    <li>
      <Link className="nav--item" to={href} children={title} />
    </li>
  )
}

export default MenuItem
