import * as React from "react"
import { ContextProvider } from "../utils/ContextProvider"
import Header from "./header"

const initialState = { menuOpen: false }

const Layout = props => {
  const { location, title, children, headerMiddleContent } = props
  // @ts-ignore
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <ContextProvider>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <Header middleContent={headerMiddleContent} />
        <main>{children}</main>
      </div>
    </ContextProvider>
  )
}

export default Layout
