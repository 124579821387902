import * as React from "react"

const initialState = { state: undefined, dispatch: undefined }
const MyContext = React.createContext(initialState)

function contextReducer(state, action) {
  switch (action.type) {
    case "open": {
      return { open: true }
    }
    case "close": {
      return { open: false }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function ContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(contextReducer, { open: false })
  const value = { state, dispatch }
  return <MyContext.Provider value={value}>{children}</MyContext.Provider>
}

function useMyContext() {
  const context = React.useContext(MyContext)
  if (context === undefined) {
    throw new Error("useCount must be used within a CountProvider")
  }
  return context
}

export { ContextProvider, useMyContext }
