import { Link, useI18next } from "gatsby-plugin-react-i18next"
import * as React from "react"
// @ts-ignore
import arrowSVG from "../images/arrow.svg"

const LanguageSelector = () => {
  const { languages, originalPath, language } = useI18next()
  const [openLang, setOpenLang] = React.useState(false)
  const switchOpenLang = () => setOpenLang(openLang => !openLang)
  return (
    <div className={`lang-selector ${openLang ? "openLang" : ""}`}>
      <div
        className="left"
        onClick={switchOpenLang}
        onKeyDown={switchOpenLang}
        role="button"
        tabIndex={0}
      >
        <span className="lang-label">{language}</span>
        <div className="arrow-container">
          <img className="lang-arrow" src={arrowSVG} alt="Selector arrow" />
        </div>
      </div>
      <div className="right">
        <ul className="languages">
          {languages.map(
            lng =>
              lng !== language && (
                <li key={lng}>
                  <Link to={originalPath} language={lng} className="lang-label">
                    {lng}
                  </Link>
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  )
}

export default LanguageSelector
