import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
// @ts-ignore
import ardarazWhiteSVG from "../images/ardaraz-white.svg"
// @ts-ignore
import crossSVG from "../images/cross.svg"
// @ts-ignore
import hamburgerSVG from "../images/hamburger.svg"
import { useMyContext } from "../utils/ContextProvider"
import Menu from "./menu"

const Header = ({ middleContent }) => {
  const {
    state: { open },
    dispatch,
  } = useMyContext()
  const { t } = useTranslation()

  const onClick = () => {
    if (open) {
      dispatch({ type: "close" })
    } else {
      dispatch({ type: "open" })
    }
  }

  return (
    <header>
      <Menu menuOpen={open} />
      <div className="headerMenu">
        <div
          className={open ? "open" : "close"}
          onClick={onClick}
          onKeyDown={onClick}
          role="button"
        >
          <div className="hamburger" tabIndex={0}>
            <img src={hamburgerSVG} alt="icon menu" />
          </div>
          <div className="cross" tabIndex={0}>
            <img src={crossSVG} alt="close menu" />
          </div>
          <div className="headerLabel">{t("menu.menu")}</div>
        </div>
      </div>

      {middleContent}
      <div
        className={`desktopClose ${open ? "open" : ""}`}
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
      />
      <div className="headerLink">
        <Link to="/">
          <img className="logo" src={ardarazWhiteSVG} alt="logo" />
        </Link>
      </div>
    </header>
  )
}

export default Header
